import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { ClientQVault } from '@qwealth/qvault';

const {
  REACT_APP_QWEALTH_AUTH_REGION,
  REACT_APP_QWEALTH_AUTH_USER_POOL_ID,
  REACT_APP_QWEALTH_AUTH_CLIENT_ID,
  REACT_APP_QWEALTH_S3_BUCKET,
  REACT_APP_QWEALTH_IDENTITY_POOL_ID,
} = process.env;

Amplify.configure({
  Auth: {
    identityPoolId: REACT_APP_QWEALTH_IDENTITY_POOL_ID,
    region: REACT_APP_QWEALTH_AUTH_REGION,
    userPoolId: REACT_APP_QWEALTH_AUTH_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_QWEALTH_AUTH_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: REACT_APP_QWEALTH_S3_BUCKET,
      region: REACT_APP_QWEALTH_AUTH_REGION,
    },
  },
});

export default withAuthenticator(ClientQVault);
