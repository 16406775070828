import { FC, useState } from 'react';
import { Form } from 'react-bootstrap';

import { Box } from '@qwealth/qcore';

import CognitoWrappedClientQVault from 'components/common/CognitoWrappedClientQVault';
import { useAppSelector } from 'data/store';
import { selectComplianceContact } from 'data/selectors/compliance';

const ComplianceQVault: FC = (): JSX.Element => {
  const selectedContact = useAppSelector(selectComplianceContact);
  const [isAdvisor, setIsAdvisor] = useState<boolean>(true);

  return (
    <Box width="100%" overflowY="scroll" display="flex" flexDirection="column" gap="default">
      Client View
      <Form.Check
        id="isAdvisor"
        type="switch"
        checked={!isAdvisor}
        onChange={() => {
          setIsAdvisor(prevState => !prevState);
        }}
      />
      <CognitoWrappedClientQVault
        qid={selectedContact?.QID as string}
        name={selectedContact?.QID as string}
        isAdvisor={isAdvisor}
      />
    </Box>
  );
};

export default ComplianceQVault;
