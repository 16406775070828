import { IPersonDto, MemberType } from '@qwealth/qdata';

export const MEMBER_ORDER: Array<MemberType> = [
  'Primary Client',
  'Secondary Client',
  'Additional Client',
  'Non-Client Spouse',
  'Beneficiary',
  'Dependant'];

export const orderMembersByType = (m1: IPersonDto, m2: IPersonDto): number => {
  const memberType1: MemberType = m1.householdMembers[0]?.memberType as MemberType ?? 'Dependant';
  const memberType2: MemberType = m2.householdMembers[0]?.memberType as MemberType ?? 'Dependant';
  return MEMBER_ORDER.indexOf(memberType1) - MEMBER_ORDER.indexOf(memberType2);
}