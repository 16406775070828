import { Dispatch } from 'redux';
import axios from 'axios';
import { HouseholdMetadata, MemberType } from '@qwealth/qdata';
import {
  errorHandler,
  REACT_APP_QWEALTH_QC_API,
  REACT_APP_QWEALTH_API,
} from '../../services/axiosService';
import { putHouseholdMetadata } from './householdMetadata';
import { AppThunkDispatch } from '../store';
import { updateHouseholdRequest } from '../data-layer/household';
import { putActiveContact } from './contacts';

export const RESET_HOUSEHOLD_STATE = 'RESET_HOUSEHOLD_STATE';
export const resetHouseholdState = () => ({ type: RESET_HOUSEHOLD_STATE });

export const updateHouseholdCache = (householdId: string) => (dispatch: Dispatch): Promise<void> =>
  axios
    .put(`${REACT_APP_QWEALTH_QC_API}/qcentral/households/${householdId}`)
    .catch(errorHandler(dispatch, 'Failed to update household cache'));

export interface AssociateToHouseholdPayload {
  memberType: MemberType;
  householdQid: string;
  memberQid: string;
  email?: string;
}

// TODO: move this into qdata
export const associateContactToHousehold = (payload: AssociateToHouseholdPayload) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios.post(`${REACT_APP_QWEALTH_API}/associateContact`, payload)
    .catch(errorHandler(dispatch, 'Failed to associate contact to household'));

export const updateHouseholdDetails = (household: HouseholdMetadata) => (dispatch: Dispatch): Promise<void> =>
  updateHouseholdRequest({
    name: household.name,
    iaCode: household.iaCode,
    insuranceCode: household.insuranceCode,
    QID: household.QID,
    status: household.status,
    isInvestmentClient: household.isInvestmentClient as 0 | 1
  })
    .then(() => {
      dispatch(putHouseholdMetadata(household));
      // @ts-ignore
      dispatch(updateHouseholdCache(household.QID));
    })
    .catch(errorHandler(dispatch));

export const deleteHousehold = (householdId: string) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios
    .delete(`${REACT_APP_QWEALTH_API}/households/${householdId}`)
    .catch(errorHandler(dispatch, 'Failed to delete household. Please contact technology@qwealth.com.'));
