import { FC, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Loader } from '@qwealth/qcore';
import { DemographicInfoConfirmation } from '@qwealth/kyc';
import { BsPencil } from 'react-icons/bs';
import EditDemographicInfoModal from './EditDemographicInfoModal';
import { EditButton } from '../../Common';
import { RootState } from 'data/store';

const additionalFields = {
  isHighRisk: { label: 'Enhanced KYC', isBoolean: true },
  memberType: { label: 'Member Type' },
};

interface Props {
  householdQID: string;
  contactQID: string;
  isCompliance?: boolean;
}

const DemographicInfo: FC<Props> = ({ householdQID, contactQID, isCompliance }): ReactElement => {
  const demographicInfo = useSelector((state: RootState) => state.contacts.contact);
  const loading = useSelector((state: RootState) => state.contacts.loading);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  if (loading.contact) {
    return <Loader />;
  }

  return (
    <Box overflow="auto" style={{ position: 'relative' }}>
      {!isCompliance && (
        <EditButton
          bg="transparent"
          color="primaryText"
          variant="icon"
          onClick={() => {
            setShowEditModal(true);
          }}
        >
          <BsPencil />
        </EditButton>
      )}
      <DemographicInfoConfirmation
        demographicInfo={demographicInfo}
        additionalFields={additionalFields}
      />

      <EditDemographicInfoModal
        contactQID={contactQID}
        householdQID={householdQID}
        demographicInfo={demographicInfo}
        shouldShowModal={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
      />
    </Box>
  );
};

export default DemographicInfo;
