import React from 'react';
import styled from '@emotion/styled';
import { Collapse } from 'react-bootstrap';
import { IconType } from 'react-icons';
import themeGet from '@styled-system/theme-get';
import { Box } from '@qwealth/qcore';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { StyledSideNavLinkItem } from './SideNavLinkItem';

interface DropdownItemProps {
  title: string;
  onClick: () => void;
  activeClass?: string;
}

interface SideNavDropdownProps {
  title: string;
  icon: React.ReactElement | IconType;
  dropdownItems: DropdownItemProps[];
}

const StyledSideNavDropdown = styled(StyledSideNavLinkItem)`
  :hover {
    div.hide-on-collapsed > button {
      color: ${themeGet('colors.primaryLight.1')};
      transition-delay: 0.25s;
    }
  }
`;
const StyledDropdownToggle = styled(StyledSideNavLinkItem)`
  padding: 0;
  &:after {
    left: -81px;
  }
`;

const StyledExpandedItem = styled(StyledSideNavLinkItem)`
  margin-left: 4.5rem;
`;

export const SideNavDropdown: React.FC<SideNavDropdownProps> = ({ title, icon, dropdownItems }) => {
  const Icon = icon;
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <div aria-controls="collapse" aria-expanded={open} onClick={() => setOpen(!open)}>
      <StyledSideNavDropdown>
        {/* @ts-ignore */}
        <Icon />
        <Box
          className="hide-on-collapsed"
          ml="large"
          textAlign="left"
          style={{ wordBreak: 'break-word', padding: 0 }}
        >
          <StyledDropdownToggle>
            {title}
            {open ? <GoTriangleUp /> : <GoTriangleDown />}
          </StyledDropdownToggle>
        </Box>
      </StyledSideNavDropdown>
      <Collapse in={open}>
        {/* Wrapped in div to ensure smooth animation */}
        <div style={{ padding: 0, margin: 0 }}>
          {dropdownItems?.map((dropdownItem, index) => {
            return (
              <StyledExpandedItem
                className={dropdownItem?.activeClass}
                key={`${index}-${dropdownItem.title}`}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                  dropdownItem.onClick();
                }}
              >
                {dropdownItem.title}
              </StyledExpandedItem>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default SideNavDropdown;
