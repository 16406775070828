import { FC, ReactElement, useEffect, useMemo, useState } from 'react';

import { Box, SelectInput2, Tab, Tabs } from '@qwealth/qcore';
import { RTQ, SelectOption } from '@qwealth/qdata';
import RTResponses from '@qwealth/rtq/build/es/components/Common/RTResponses';

import { toEditorState } from 'data/helpers/editorState';
import RtqNotesLabel from 'components/common/RTQ/RTQNotesLabel';
import RtqNoteField from 'components/common/RtqNoteList/RtqNoteField';
import InputForm from '../../../components/common/InputForm';

interface RtqNoteListProps {
  rtq: RTQ;
}

const RtqNoteList: FC<RtqNoteListProps> = ({ rtq }): ReactElement => {
  const [selectedPmNote, setSelectedPmNote] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('pmNotes');

  const mutableNotes = rtq.pmNotes.map(n => ({ ...n, state: toEditorState(n.note) }));

  useEffect(() => {
    const uuids = mutableNotes.map(n => n.uuid);
    if (!uuids.includes(selectedPmNote) && uuids.length > 0) {
      setSelectedPmNote(uuids[0]);
    }
  }, [selectedPmNote, mutableNotes]);

  const options: SelectOption<string>[] = useMemo(() => {
    return mutableNotes.map((n, idx) => ({
      label: `RTQ Note ${idx + 1} - ${n.noteDate}`,
      value: n.uuid,
    }));
  }, [mutableNotes]);

  return (
    <>
      <Tabs
        mb="default"
        onSelect={tab => {
          setSelectedTab(tab);
        }}
        selectedTab={selectedTab}
      >
        <Tab id="pmNotes">PM Notes</Tab>
        <Tab id="rtqResponses">RTQ Responses</Tab>
      </Tabs>

      {selectedTab === 'pmNotes' && (
        <Box overflowY="scroll">
          <Box textStyle="formLabel" mt="largest">
            <RtqNotesLabel />
          </Box>
          <Box display="flex" flexDirection="row" gap="default" mb="largest">
            <InputForm>
              <SelectInput2
                value={selectedPmNote}
                onChange={val => setSelectedPmNote(val as string)}
                data={options}
                toLabel={opt => opt.label}
                toValue={opt => opt.value}
              />
            </InputForm>
          </Box>
          <Box>
            {mutableNotes.map(note => {
              if (note.uuid !== selectedPmNote) {
                return null;
              }
              return <RtqNoteField key={note.uuid} isLocked mutableRtqNote={note} />;
            })}
          </Box>
        </Box>
      )}

      {selectedTab === 'rtqResponses' && (
        <Box overflowY="scroll">
          {/* @ts-ignore */}
          <RTResponses hideConfirmation quizContext={'Contact'} rtqId={100204} />
        </Box>
      )}
    </>
  );
};

export default RtqNoteList;
