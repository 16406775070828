import { FC, useEffect, useState } from 'react';

import { Box } from '@qwealth/qcore';
import { S3Item } from '@qwealth/qvault';

import DocumentSelection from './DocumentSelection';
import {
  AddressesInfoConfirmation,
  BankAccountForm,
  BankingInfoConfirmation,
  defaultBankingInfo,
  EmploymentInfoConfirmation,
  FinancialInfoConfirmation,
  GovernmentIdConfirmation,
  RegulatoryInfoConfirmation,
  useAddressInfo,
  useFinancialInfo,
  useGovernmentInfo,
  useRegulatoryInfo,
} from '@qwealth/kyc';
import { fetchRTQWithContactQID, RTQ } from '@qwealth/qdata';

import { useAppSelector } from 'data/store';
import { selectComplianceContact, selectComplianceHousehold } from 'data/selectors/compliance';
import { shortCode } from 'data/models/Compliance';
import PMNotesCard from './PMNotesCard';
import Definitions from './Definitions';
import PDFViewer from '../PDFViewer/PdfViewer';
import DemographicInfo from 'components/Contacts/ContactDetails/VitalTab/DemographicInfo';
import CitizenshipAndResidency from 'components/Contacts/ContactDetails/VitalTab/CitizenshipAndResidency';
import ComplianceQVault from './ComplianceQVault';

interface ComplianceTasksCardProps {
  taskName: string;
  taskToDisplay: shortCode;
  changeTo: (changeTo: shortCode) => void;
}

const ComplianceTasksCard: FC<ComplianceTasksCardProps> = ({
  taskToDisplay,
  changeTo,
}): JSX.Element => {
  const selectedContact = useAppSelector(selectComplianceContact);
  const selectedHousehold = useAppSelector(selectComplianceHousehold);
  const { governmentInfo, setGovernmentInfo } = useGovernmentInfo(selectedContact?.QID);
  const { financialInfo, setFinancialInfo } = useFinancialInfo(selectedContact?.QID);
  const [bankingInfo, setBankingInfo] = useState<BankAccountForm>({
    nbinBankAccountDetails: [defaultBankingInfo],
  });
  const { addressesInfo, setAddressesInfo, getAddressFormData } = useAddressInfo(
    selectedContact?.QID as string,
  );
  const { regulatoryInfo, setRegulatoryInfo } = useRegulatoryInfo(selectedContact?.QID as string);
  const [selectedContactRTQ, setSelectedContactRTQ] = useState<RTQ | undefined>();
  const [pdfToDisplay, setPdfToDisplay] = useState<S3Item>();

  useEffect(() => {
    getAndSetRTQ();
  }, [selectedContact]);

  const getAndSetRTQ = async () => {
    let rtq: RTQ | undefined = undefined;
    const rtqs = await fetchRTQWithContactQID(selectedContact?.QID as string);

    if (Array.isArray(rtqs) && rtqs.length > 0) {
      rtq = rtqs[0];
    } else if (rtqs !== undefined && !Array.isArray(rtqs)) {
      rtq = rtqs;
    }
    setSelectedContactRTQ(rtq);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="50%"
      height="100vh"
      backgroundColor="white"
      padding="large"
      boxShadow="layout"
    >
      <Box
        fontSize="appTitle"
        fontWeight="bold"
        marginBottom="large"
        display="flex"
        flexDirection="column"
      >
        {taskToDisplay}
        <Box height="1px" backgroundColor="gray.0" />
      </Box>
      {taskToDisplay === 'SelectDocument' && (
        <DocumentSelection setPdfToDisplay={setPdfToDisplay} changeTo={changeTo} />
      )}

      {taskToDisplay === 'QVault' && <ComplianceQVault />}

      {taskToDisplay === 'PDFViewer' && (
        <Box minWidth="100%">
          <PDFViewer defaultPdf={pdfToDisplay} />
        </Box>
      )}

      {taskToDisplay === 'All Contact Info' && (
        <Box overflowY="scroll">
          <DemographicInfo
            contactQID={selectedContact?.QID as string}
            householdQID={selectedHousehold?.QID as string}
            isCompliance={true}
          />
          <AddressesInfoConfirmation addressesInfo={addressesInfo} />
          <CitizenshipAndResidency contactQID={selectedContact?.QID as string} />
          <RegulatoryInfoConfirmation regulatoryInfo={regulatoryInfo} context={'Contact'} />
          <EmploymentInfoConfirmation contactQid={selectedContact?.QID as string} />
          <GovernmentIdConfirmation
            governmentInfo={governmentInfo}
            additionalFields={{
              verificationMethod: { label: 'Verification Method' },
            }}
          />
          <BankingInfoConfirmation bankingInfo={bankingInfo} />
          <FinancialInfoConfirmation financialInfo={financialInfo} />

          <Box marginTop="largest" fontSize="appTitle" fontWeight="bold">
            PM Notes & RTQ Responses
          </Box>
          {/* ToDo: Remove check to see that RTQ is defined. I think this is only an issue with QW-QW-1-000365 but need to investigate further */}
          {selectedContactRTQ && <PMNotesCard rtq={selectedContactRTQ as RTQ} />}
          <Box marginTop="largest"></Box>
          <Definitions />
        </Box>
      )}
    </Box>
  );
};

export default ComplianceTasksCard;
