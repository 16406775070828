import { FC, ReactElement } from 'react';
import { Box } from '@qwealth/qcore';

const Definitions: FC = (): ReactElement => {
  return (
    <Box overflowY="scroll">
      <Box fontSize="sectionTitle" fontWeight="bold">
        Terms & Definitions
      </Box>

      <Box marginTop="default" marginBottom="default" fontWeight="bold">
        Are you or any of your family members or close associates a Domestic Politically Exposed
        Person as defined under AML regulations?
      </Box>

      <Box>
        A politically exposed person (PEP) is a person who holds, or has held within the last 5
        years, a specific office or position in or on behalf of a government, either domestically or
        foreign, including:
      </Box>

      <Box as="ul" gap="default">
        <Box as="li">a head of state or government</Box>
        <Box as="li">Governor General, lieutenant governor or head of government</Box>
        <Box as="li">
          a member of the executive council of government or member of a legislature
        </Box>
        <Box as="li">deputy minister (or equivalent)</Box>
        <Box as="li">an ambassador or an ambassador's attaché or counsellor</Box>
        <Box as="li">a military general (or above)</Box>
        <Box as="li">president of a state-owned company or bank</Box>
        <Box as="li">head of a gov't agency</Box>
        <Box as="li">a judge</Box>
        <Box as="li">leader or president of a political party represented in a legislature</Box>
        <Box as="li">
          mayor (head of a city, town, village, or rural or metropolitan municipality)
        </Box>
      </Box>

      <Box>The following family members are also regarded as PEPs:</Box>
      <Box as="ul">
        <Box as="li">their spouse or common-law partner</Box>
        <Box as="li">their child, mother, or father</Box>
        <Box as="li">the parent of spouse or common-law partner</Box>
        <Box as="li">a child of their mother or father (sibling)</Box>
      </Box>

      <Box>
        A close associate is an individual who is closely connected to a PEP for personal or
        business reasons and is also regarded as a PEP. Examples include a person who is:
      </Box>
      <Box as="ul">
        <Box as="li">a business partner</Box>
        <Box as="li">in a romantic relationship</Box>
        <Box as="li">involved in financial transactions</Box>
        <Box as="li">a prominent member of the same political party or union</Box>
        <Box as="li">serving as a member of the same board</Box>
        <Box as="li">closely carrying out charitable works</Box>
      </Box>
    </Box>
  );
};

export default Definitions;
