import { combineReducers } from 'redux';
import {
  accountsReducer,
  foaReducer,
  incomeSummaryReducer,
  legalEntitiesReducer,
  monthOverMonthReducer,
  notificationsReducer,
  partnersReducer,
  personsReducer,
  teamReducer,
  valuesReducer
} from '@qwealth/qdata';
import IMA from './IMA';
import KYC from './KYC';
import RTQ from './RTQ';
import activities from './activities';
import annualReviews from './annualReviews';
import auth from './auth';
import autotransactions from './autoTransactions';
import axios from './axios';
import cases from './cases';
import communication from './communication';
import contacts from './contacts';
import docusignEnvelopes from './docusignEnvelopes';
import feeRuns from './feeRuns';
import feesExclusions from './feesExclusions';
import feesGroups from './feesGroups';
import feesSchedules from './feesSchedules';
import householdActiveFlows from './householdActiveFlows';
import householdActivities from './householdActivities';
import householdMeetingNotes from './householdMeetingNotes';
import householdMetadata from './householdMetadata';
import householdOpportunities from './householdOpportunities';
import householdPortalAccess from './householdPortalAccess';
import householdRelatedContacts from './householdRelatedContacts';
import legalEntityRelatedContacts from './legalEntityRelatedContacts';
import messages from './messages';
import microsoft from './microsoft';
import myList from './myList';
import openingAccounts from './openingAccounts';
import prospectFlows from './prospectFlows';
import qbias from './qbias';
import qnotes from './qnotes';
import recentHistory from './recentHistory';
import reports from './reports';
import responsibilityFilter from './responsibilityFilter';
import users from './users';
import { RESET_HOUSEHOLD_STATE } from '../actions/households';
import compliance from './compliance';

const allReducers = combineReducers({
  accounts: accountsReducer,
  activities,
  annualReviews,
  auth,
  autotransactions,
  axios,
  cases,
  communication,
  compliance,
  contacts,
  docusignEnvelopes,
  feeRuns,
  feesExclusions,
  feesGroups,
  feesSchedules,
  functionOfAssets: foaReducer,
  householdActivities,
  householdMeetingNotes,
  householdMetadata,
  householdPortalAccess,
  householdOpportunities,
  householdRelatedContacts,
  householdActiveFlows,
  IMA,
  incomeSummary: incomeSummaryReducer,
  legalEntities: legalEntitiesReducer,
  legalEntityRelatedContacts,
  messages,
  microsoft,
  myList,
  monthOverMonth: monthOverMonthReducer,
  notifications: notificationsReducer,
  openingAccounts,
  partners: partnersReducer,
  persons: personsReducer,
  prospectFlows,
  qbias,
  qnotes,
  recentHistory,
  reports,
  responsibilityFilter,
  RTQ,
  KYC,
  team: teamReducer,
  users,
  values: valuesReducer
});
// this should be in alpha-order

const rootReducer = (state, action) => {
  // reset household related states
  if (action.type === RESET_HOUSEHOLD_STATE) {
    state = {
      ...state,
      accounts: undefined,
      communication: undefined,
      householdActivities: undefined,
      householdActiveFlows: undefined,
      householdMetadata: undefined,
      householdMeetingNotes: undefined,
      householdRelatedContacts: undefined,
      KYC: undefined,
      qbias: undefined,
      qnotes: undefined,
      RTQ: undefined,
      team: undefined,
      values: undefined,
      compliance: undefined,
    };
  }
  return allReducers(state, action);
};

export default rootReducer;
