import { FC, ReactNode } from 'react';
import { Box, BoxProps, styled, themeGet } from '@qwealth/qcore';

interface GetBorderProps {
  hasError?: boolean;
}

/** @deprecated TODO: use qcore's getBorder fn after 2.6.1+ */
export const getBorder = (props: GetBorderProps): string => {
  const color = themeGet(props.hasError ? 'colors.error' : 'colors.gray.3')(props);
  return `border: 1px solid ${color}`;
};

interface Props extends BoxProps {
  hasError?: boolean;
  children?: ReactNode;
}

export const EditorWrapper: FC<Props> = styled(Box)`
  ${getBorder};
  border-radius: ${themeGet('radii.default')};
  padding: ${themeGet('space.default')};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    box-shadow: ${themeGet('shadows.inputFocus')};
    outline: 0;
  }
`;
