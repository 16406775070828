import { ContentState, convertFromHTML, convertFromRaw, EditorState } from 'draft-js';
import { isJsonString } from './data';

// this takes a html or rtf string
export const buildEditorStateFromString = (content: string): EditorState => {
  const blocksFromHTML = convertFromHTML(content);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return EditorState.createWithContent(state);
};

export const buildEditorState = (text?: string): EditorState => {
  let editorState: EditorState = EditorState.createEmpty();

  try {
    if (text && isJsonString(text)) {
      editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(text)));
    }
  } catch (error) {
    console.error('Could not parse editor state', error);
  }

  return editorState;
};

export const toEditorState = (note: string): EditorState => {
  let state: EditorState = EditorState.createEmpty();
  try {
    if (isJsonString(note)) {
      state = EditorState.createWithContent(convertFromRaw(JSON.parse(note)));
    } else {
      state = EditorState.createWithContent(ContentState.createFromText(note));
    }
  } catch (err) {
    console.error(err);
  }

  return state;
}
