import { createReducer } from '@reduxjs/toolkit';
import {
  getComplianceHouseholds,
  setComplianceContactChecklists,
  setComplianceSelectedChecklist,
  setComplianceSelectedContact,
  setComplianceSelectedHousehold,
  updateComplianceClient,
} from 'data/actions/compliance';
import {
  complianceContact,
  complianceHousehold,
  contactChecklist,
  complianceQuestion,
} from 'data/models/Compliance';

export interface State {
  selectedHousehold: complianceHousehold | undefined;
  selectedContact: complianceContact | undefined;
  selectedChecklist: complianceQuestion[] | undefined;
  checklists: contactChecklist[] | undefined;
  households: complianceHousehold[];
}

export const initialState: State = {
  selectedHousehold: undefined,
  selectedContact: undefined,
  selectedChecklist: undefined,
  checklists: undefined,
  households: [],
};

export default createReducer(initialState, builder => {
  builder.addCase(updateComplianceClient, (state, action) => ({
    ...state,
    client: action.payload,
  }));

  builder.addCase(getComplianceHouseholds, (state, action) => ({
    ...state,
    households: action.payload,
  }));

  builder.addCase(setComplianceSelectedHousehold, (state, action) => ({
    ...state,
    selectedHousehold: action.payload,
  }));

  builder.addCase(setComplianceSelectedContact, (state, action) => ({
    ...state,
    selectedContact: action.payload,
  }));

  builder.addCase(setComplianceContactChecklists, (state, action) => ({
    ...state,
    checklists: action.payload,
  }));

  builder.addCase(setComplianceSelectedChecklist, (state, action) => ({
    ...state,
    selectedChecklist: action.payload,
  }));
});
