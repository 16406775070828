import React, { FC, useEffect, useState } from 'react';
import { BsFileEarmarkPdfFill } from 'react-icons/bs';

import { Box, styled, themeGet } from '@qwealth/qcore';
import { getPathContents, getRootPath, S3Item } from '@qwealth/qvault';

import { shortCode } from 'data/models/Compliance';
import { useAppSelector } from 'data/store';
import { selectComplianceContact } from 'data/selectors/compliance';
import BackButton from '../Common/BackButton';

const RelatedDocument = styled.button`
  width: 100%;
  background-color: ${themeGet('colors.gray.4')};
  padding: ${themeGet('space.large')};
  border-radius: ${themeGet('radii.default')};
  font-size: ${themeGet('fontSizes.large')};
  font-weight: ${themeGet('fontWeights.default')};
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${themeGet('space.default')};
  border: none;

  &:hover {
    color: ${themeGet('colors.primary')};
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

interface DocumentSelectionProps {
  changeTo: (changeTo: shortCode) => void;
  setPdfToDisplay: React.Dispatch<React.SetStateAction<S3Item | undefined>>;
}

const DocumentSelection: FC<DocumentSelectionProps> = ({
  changeTo,
  setPdfToDisplay,
}): JSX.Element => {
  const [pdfs, setPdfs] = useState<S3Item[]>();
  const [numCharactersToSkip, setNumCharacterToSkip] = useState<number>(0);
  const selectedContact = useAppSelector(selectComplianceContact);

  useEffect(() => {
    getFilesPaths();
  }, []);

  const getFilesPaths = async () => {
    const rootPath = getRootPath(selectedContact?.QID as string, true);
    const pathToDocusignFiles = rootPath + '/Onboarding/Docusign';
    const filePathContents = await getPathContents(pathToDocusignFiles, true);
    setNumCharacterToSkip(pathToDocusignFiles.length + 1); // +1 for the '/' character
    setPdfs(filePathContents);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box fontSize="sectionTitle" fontWeight="bold">
        Related Documents Found
      </Box>

      <Box display="flex" flexDirection="column" marginTop="large" marginBottom="large" gap="large">
        {pdfs === undefined || (pdfs.length === 0 && <Box>No Documents found</Box>)}
        {pdfs?.map(pdf => {
          return (
            <RelatedDocument
              onClick={() => {
                setPdfToDisplay(pdf);
                changeTo('PDFViewer');
              }}
            >
              <BsFileEarmarkPdfFill size={32} color="black" />
              {pdf.key && pdf.key.slice(numCharactersToSkip, pdf.key.length)}
            </RelatedDocument>
          );
        })}
      </Box>

      <BackButton
        clickBack={() => changeTo('QVault')}
        hasIcon={false}
        btnText={'Go To QVault'}
        textSize="default"
      />
    </Box>
  );
};

export default DocumentSelection;
