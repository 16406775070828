import { FC } from 'react';
import { BsFolderFill, BsHouseFill, BsPersonFill } from 'react-icons/bs';
import { styled, themeGet } from '@qwealth/qcore';

interface StyledComplianceHeaderButtonProps {
  isCurrectSection: boolean;
}

const StyledComplianceHeaderButton = styled.button<StyledComplianceHeaderButtonProps>`
  color: ${props =>
    props.isCurrectSection ? themeGet('colors.primary') : themeGet('colors.gray.1')};
  background-color: ${themeGet('colors.white')};
  border: none;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${themeGet('space.small')};
  font-size: ${themeGet('fontSizes.large')};
  font-weight: ${themeGet('fontWeights.bold')};

  &:hover {
    color: ${themeGet('colors.primaryLight.1')};
  }
`;

interface ComplianceHeaderButtonProps {
  name: string;
  icon: 'Folder' | 'House' | 'Person';
  isCurrentSection: boolean;
  onClick: () => void;
}

const ComplianceHeaderButton: FC<ComplianceHeaderButtonProps> = ({
  name,
  icon,
  isCurrentSection,
  onClick,
}): JSX.Element => {
  return (
    <StyledComplianceHeaderButton isCurrectSection={isCurrentSection} onClick={onClick}>
      {icon === 'Folder' && <BsFolderFill />}
      {icon === 'House' && <BsHouseFill />}
      {icon === 'Person' && <BsPersonFill />}
      {name}
    </StyledComplianceHeaderButton>
  );
};

export default ComplianceHeaderButton;
