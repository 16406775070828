import React, { useState } from 'react';
import { Box, styled, themeGet } from '@qwealth/qcore';
import { complianceQuestion, shortCode, yesNoNaOption } from 'data/models/Compliance';
import CompletionCheckbox from '../Common/CompletionCheckbox';
import ComplianceButton from './ComplianceButton';

const StyledQuestionLink = styled.button`
  border: none;
  background: none;
  text-align: left;
  font-size: ${themeGet('fontSizes.default')};

  &:hover {
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

interface SectionQuestionProps {
  question: complianceQuestion;
  isCompliant: boolean | undefined;
  changeTo: (taskToDisplay: shortCode) => void;
  isAdvisorView?: boolean;
  selected?: yesNoNaOption;
  incrementNonCompliance: (change: 1 | -1) => void;
  updateQuestionAnswer: (questionId: string, option: string) => void;
  updateParentQuestion?: (prevOption: yesNoNaOption, option: yesNoNaOption) => void;
}

const SectionQuestion: React.FC<SectionQuestionProps> = ({
  question,
  isCompliant,
  isAdvisorView,
  updateParentQuestion,
  changeTo,
  updateQuestionAnswer,
  incrementNonCompliance,
}) => {
  const [optionSelected, setOptionSelected] = useState<yesNoNaOption>(
    isCompliant ? 'yes' : undefined,
  );

  const clickSelection = (option: 'yes' | 'no' | 'n/a') => {
    updateQuestionAnswer(question.id.toString(), option as string);
    const prevOption = optionSelected;
    if (prevOption === 'no') {
      incrementNonCompliance(-1);
    } else if (option === 'no') {
      incrementNonCompliance(1);
    }

    option === optionSelected ? setOptionSelected(undefined) : setOptionSelected(option);

    if (updateParentQuestion) {
      updateParentQuestion(prevOption, option);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="default"
      alignItems={updateParentQuestion ? '' : 'center'}
    >
      {isAdvisorView ? (
        <CompletionCheckbox isCompliant={isCompliant} />
      ) : (
        <Box display="flex" flexDirection="row" gap="default">
          <ComplianceButton
            type="yes"
            selected={optionSelected === 'yes'}
            clickOption={() => clickSelection('yes')}
          />
          <ComplianceButton
            type="no"
            selected={optionSelected === 'no'}
            clickOption={() => clickSelection('no')}
          />
          {question?.type === 'yes_no_na' && (
            <ComplianceButton
              type="n/a"
              selected={optionSelected === 'n/a'}
              clickOption={() => clickSelection('n/a')}
            />
          )}
        </Box>
      )}
      <StyledQuestionLink onClick={() => changeTo(question.shortCode)}>
        {question?.text}
      </StyledQuestionLink>
    </Box>
  );
};

export default SectionQuestion;
