import React, { ReactElement } from 'react';
import { Modal } from 'react-bootstrap';
import { loadSingleContact } from 'data/actions/contacts';
import { Box } from '@qwealth/qcore';
import { IPersonDto } from '@qwealth/qdata';
import { CitizenshipAndResidency, saveCitizenshipAndResidency } from '@qwealth/kyc';
import { useAppDispatch } from 'data/store';

interface Props {
  demographicInfo: IPersonDto;
  contactQID: string;
  shouldShowModal: boolean;
  onClose: () => void;
}

const EditModal = ({ demographicInfo, contactQID, shouldShowModal, onClose }: Props): ReactElement => {
  const dispatch = useAppDispatch();

  const submitHandler = async (data: Partial<IPersonDto>) => {
    await dispatch(saveCitizenshipAndResidency(contactQID, data));
    await dispatch(loadSingleContact(contactQID));
    onClose();
  };

  return (
    <Modal onHide={onClose} show={shouldShowModal} size="xl">
      <Modal.Header closeButton>
        <Box textStyle="sectionTitle">Edit Citizenship and Tax Residency</Box>
      </Modal.Header>
      <Modal.Body>
        <CitizenshipAndResidency
          demographicInfo={demographicInfo}
          editing
          onSubmit={submitHandler}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
