import React, { FC, ReactElement } from 'react';
import { Tooltip } from 'react-tooltip';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Box } from '@qwealth/qcore';

const RtqNotesLabel: FC = (): ReactElement => (
  <Box display="flex" flexDirection="row" textStyle="formLabel" mt="default">
    RTQ Notes*
    <Box data-tooltip-id="rtqNotes" data-tip ml="small">
      <BsInfoCircleFill />
    </Box>
    <Tooltip id="rtqNotes">
      <p>Internal notes by the APM/PM to justify the client’s mandate selection</p>
    </Tooltip>
  </Box>
);

export default RtqNotesLabel;
