import { deleteRelatedContact, fetchRelatedContactsWithQID, RelatedContact } from '@qwealth/qdata';
import { createAction } from '@reduxjs/toolkit';

import { RELATED_CONTACTS_LABEL } from 'data/refData/constants';
import { errorHandler } from 'services/axiosService';
import { AppThunkDispatch, RootState } from '../store';
import { RelatedContactForm, saveRelatedContact } from './relatedContact';
import { saveNewRelatedContact } from './contacts';
import { RelationshipFormWithNewContactModel } from '../models/relatedContact';

const PUT_LEGAL_ENTITY_RELATED_CONTACTS = 'PUT_LEGAL_ENTITY_RELATED_CONTACTS';
const REMOVE_LEGAL_ENTITY_RELATED_CONTACT = 'REMOVE_LEGAL_ENTITY_RELATED_CONTACT';
const RESET_LEGAL_ENTITY_RELATED_CONTACT = 'RESET_LEGAL_ENTITY_RELATED_CONTACT';
const SET_LEGAL_ENTITY_RELATED_CONTACT_INITIALIZED_FLAG = 'SET_LEGAL_ENTITY_RELATED_CONTACT_INITIALIZED_FLAG';
const SET_LEGAL_ENTITY_RELATED_CONTACT_LOADING_FLAG = 'SET_LEGAL_ENTITY_RELATED_CONTACT_LOADING_FLAG';

export const putLegalEntityRelatedContacts = createAction<RelatedContact[]>(PUT_LEGAL_ENTITY_RELATED_CONTACTS);
export const removeLegalEntityRelatedContact = createAction<number>(REMOVE_LEGAL_ENTITY_RELATED_CONTACT);
export const resetLegalEntityRelatedContact = createAction(RESET_LEGAL_ENTITY_RELATED_CONTACT);
export const setLegalEntityRelatedContactInitializedFlag = createAction<boolean>(SET_LEGAL_ENTITY_RELATED_CONTACT_INITIALIZED_FLAG);
export const setLegalEntityRelatedContactLoadingFlag = createAction<boolean>(SET_LEGAL_ENTITY_RELATED_CONTACT_LOADING_FLAG);


export const loadLegalEntityRelatedContacts = (legalEntityQID: string) => async (dispatch: AppThunkDispatch, getState: () => RootState): Promise<void> => {
  const state = getState().legalEntityRelatedContacts;
  if (state.initialized || state.loading) {
    return Promise.resolve();
  }
  dispatch(resetLegalEntityRelatedContact());
  dispatch(setLegalEntityRelatedContactLoadingFlag(true));
  return fetchRelatedContactsWithQID([legalEntityQID])
    .then(response => response.data)
    .then(relatedContacts => dispatch(putLegalEntityRelatedContacts(relatedContacts)))
    .catch(errorHandler(dispatch, `Failed to retrieve ${RELATED_CONTACTS_LABEL}`))
    .finally(() => {
      dispatch(setLegalEntityRelatedContactLoadingFlag(false));
      dispatch(setLegalEntityRelatedContactInitializedFlag(true));
    });
}

export const addRelatedContact = (form: RelationshipFormWithNewContactModel) => async (dispatch: AppThunkDispatch, getState: () => RootState): Promise<RelatedContact[]> => {
  try {
    let savedRelatedContacts: RelatedContact[] = [];
    if (form.isNewContact) {
      savedRelatedContacts = await saveNewRelatedContact(form);
    } else {
      savedRelatedContacts = await saveRelatedContact(form as RelatedContactForm);
    }
    const related = getState().legalEntityRelatedContacts.relatedContacts;
    dispatch(putLegalEntityRelatedContacts([...related, ...savedRelatedContacts]));
    return Promise.resolve(savedRelatedContacts);
  } catch (e) {
    await errorHandler(dispatch, 'Failed to save related contact')(e);
    return Promise.reject(e);
  }
};

export const deleteLegalEntityRelatedContact = (id: number) => async (dispatch: AppThunkDispatch): Promise<void> =>
  await deleteRelatedContact(id)
    .then(() => dispatch(removeLegalEntityRelatedContact(id)))
    .catch(errorHandler(dispatch, 'Failed to delete related contact'));
