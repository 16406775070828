import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { IconType } from 'react-icons';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { Box } from '@qwealth/qcore';

interface SideNavLinkItemProps {
  activeClass?: string;
  title: string;
  icon: React.ReactElement | IconType;
  onClick: () => void;
  isOpenInNewWindow?: boolean;
}

export const StyledSideNavLinkItem = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  color: ${themeGet('colors.white')};
  border: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100%;
  position: relative;
  height: 55px;

  &:after {
    content: '';
    transition: all 0.25s ease-in-out;
    opacity: 0;
    height: 55px;
    background: ${themeGet('colors.primaryLight.1')};
    width: 5px;
    position: absolute;
    left: -25px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${themeGet('colors.primaryLight.1')};
  }

  &:hover:after {
    opacity: 1;
  }

  &.active {
    color: ${themeGet('colors.primaryLight.1')};
    &:after {
      opacity: 1;
      display: block;
    }
  }

  svg {
    transition: all 0.15s ease-in-out;
    min-width: 2rem;
    width: 2rem;
  }
`;

/** @deprecated This method is deprecated. */
export const SideNavLinkItem: React.FC<SideNavLinkItemProps> = ({
  activeClass,
  title,
  icon,
  onClick,
  isOpenInNewWindow = false,
}) => {
  const Icon = icon;

  return (
    <StyledSideNavLinkItem className={activeClass} aria-label={title} onClick={onClick}>
      {/* @ts-ignore */}
      <Icon />
      <Box
        className="hide-on-collapsed"
        ml="large"
        textAlign="left"
        style={{ wordBreak: 'break-word' }}
      >
        {title}
      </Box>
      {isOpenInNewWindow && (
        <BsBoxArrowUpRight
          className={activeClass}
          style={{ marginLeft: '0.5rem' }}
          data-testid="newWindowIcon"
        />
      )}
    </StyledSideNavLinkItem>
  );
};
