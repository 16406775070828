import React from 'react';
import { Box, Button, styled } from '@qwealth/qcore';

export const EditButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 1em;
`;

export const SingleColumn = ({ children }) => (
  <Box gap="large" marginY="default" color="gray.1" display="flex">
    {children}
  </Box>
);
