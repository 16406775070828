import React from 'react';
import { Box, Button, styled, TextAreaInput, themeGet } from '@qwealth/qcore';

const StyledSectionNotesContainer = styled(TextAreaInput)`
  width: 100%;
  min-height: 200px;
  background-color: ${themeGet('colors.white')};
  padding: ${themeGet('space.default')};
  border-radius: ${themeGet('radii.default')};
  color: ${themeGet('colors.dark')};
  font-size: ${themeGet('fontSizes.default')};
  font-weight: ${themeGet('fontWeights.normal')};
`;

const ChecklistSectionNotes: React.FC = () => {
  return (
    <Box fontWeight="bold">
      Notes:
      <StyledSectionNotesContainer />
      <Box width="100%" display="flex" justifyContent="center">
        <Button>Submit Note</Button>
      </Box>
    </Box>
  );
};

export default ChecklistSectionNotes;
