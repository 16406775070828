import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Form as BsForm } from 'react-bootstrap';

import { Box, Button, Modal, removeEmptyValues } from '@qwealth/qcore';
import { HOUSEHOLD_MEMBER_CONTACT_TYPE, IHouseholdMemberDto, IPersonDto, MemberType, selectUser } from '@qwealth/qdata';
import {
  defaultDemographicFormData,
  DemographicInternalForm,
  demographicValidator,
  saveDemographicInfo,
  togglePrimaryEmail,
  togglePrimaryPhone,
  ValidationType,
} from '@qwealth/kyc';

import { useAppDispatch } from 'data/store';
import { loadSingleContact } from 'data/actions/contacts';
import { saveHouseholdMember } from 'data/data-layer/householdMembers';

interface Props {
  shouldShowModal: boolean;
  onClose: () => void;
  demographicInfo: Partial<IPersonDto>;
  householdQID: string;
  contactQID: string;
}

const EditDemographicInfoModal: FC<Props> = ({
  shouldShowModal,
  onClose,
  demographicInfo,
  householdQID,
  contactQID,
}) => {
  const dispatch = useAppDispatch();
  const { email: userEmail } = useSelector(selectUser);
  const [validationType, setValidationType] = useState<ValidationType>('Basic');

  const submitHandler = async data => {
    const cleanData = removeEmptyValues(data);
    await dispatch(saveDemographicInfo(contactQID, cleanData, householdQID, userEmail));
    const householdMember: IHouseholdMemberDto | undefined = demographicInfo.householdMembers && demographicInfo.householdMembers[0];
    if (demographicInfo.contactType === HOUSEHOLD_MEMBER_CONTACT_TYPE
      && householdMember
      && householdMember?.memberType !== data.memberType) {
      // TODO: this is non-deterministic in what household it's saving the memberType to. matt needs to solve this
      await dispatch(saveHouseholdMember({
        ...householdMember,
        memberType: data.memberType
      }));
    }
    await dispatch(loadSingleContact(contactQID));
    onClose();
  };

  const memberType: MemberType | undefined = useMemo(() => {
    if (demographicInfo.contactType === HOUSEHOLD_MEMBER_CONTACT_TYPE) {
      const householdMember = demographicInfo.householdMembers && demographicInfo.householdMembers[0];
      return householdMember?.memberType as MemberType;
    }
    return undefined;
  }, [demographicInfo]);

  return (
    <Form
      validate={values => demographicValidator(values, {
        context: 'QCentral',
        editableMemberType: values.contactType === HOUSEHOLD_MEMBER_CONTACT_TYPE,
        validationType
      }).result}
      onSubmit={submitHandler}
      initialValues={{
        ...defaultDemographicFormData,
        ...demographicInfo,
        memberType
      }}
      mutators={{
        ...arrayMutators,
        togglePrimaryEmail,
        togglePrimaryPhone,
      }}
      render={({
        handleSubmit,
        dirty,
        values,
        form,
        submitting,
        errors,
        touched
      }) => {
        const resetAndClose = () => {
          form.reset();
          onClose();
        };
        return (
          <Modal onClose={resetAndClose} show={shouldShowModal} size="xl" title="Edit Demographic">
            <Modal.Body>
              <DemographicInternalForm
                context="QCentral"
                isDirty={dirty}
                editing
                editableMemberType={values?.contactType === HOUSEHOLD_MEMBER_CONTACT_TYPE}
                togglePrimaryEmailMutator={form.mutators.togglePrimaryEmail}
                togglePrimaryPhoneMutator={form.mutators.togglePrimaryPhone}
                validationType={validationType}
                touched={touched}
                errors={errors}
              />
            </Modal.Body>
            <Modal.Footer>
              <BsForm.Check
                type="switch"
                label={(
                  <Box textStyle="formLabel">
                    NBIN Validation
                  </Box>)}
                checked={validationType === 'NBIN'}
                onChange={e => {
                  setValidationType(e.target.checked ? 'NBIN' : 'Basic');
                }}
              />
              <Button type="submit" onClick={handleSubmit} disabled={submitting}>
                Save
              </Button>
              <Button variant="outline" onClick={resetAndClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }}
    />
  );
};

export default EditDemographicInfoModal;
