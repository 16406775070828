import { FC } from 'react';
import { Box, styled, themeGet } from '@qwealth/qcore';
import CompletionCheckbox from '../Common/CompletionCheckbox';
import { BsChevronRight } from 'react-icons/bs';

interface StyledHouseholdChecklistButtonProps {
  isCompliant: boolean | undefined;
}

const StyledHouseholdChecklistButton = styled.button<StyledHouseholdChecklistButtonProps>`
  width: 100%;
  background-color: ${props => (props.isCompliant ? '#E7F2DF' : themeGet('colors.gray.3'))};
  padding: ${themeGet('space.default')};
  border: none;
  border-radius: ${themeGet('radii.default')};
  font-size: ${themeGet('fontSizes.large')};
  font-weight: ${themeGet('fontWeights.bold')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background-color: ${props => (props.isCompliant ? '#E7F2DF' : themeGet('colors.gray.2'))};
    color: ${themeGet('colors.primaryLight.1')};
  }
`;

interface HouseholdChecklistButtonProps {
  name: string;
  isCompliant: boolean | undefined;
  onClick: () => void;
}

const HouseholdChecklistButton: FC<HouseholdChecklistButtonProps> = ({
  name,
  isCompliant,
  onClick,
}): JSX.Element => {
  return (
    <StyledHouseholdChecklistButton isCompliant={isCompliant} onClick={onClick}>
      <Box display="flex" flexDirection="row" gap="large" alignItems="center">
        <CompletionCheckbox isCompliant={isCompliant} />
        {name}
      </Box>
      <Box marginRight="default">
        <BsChevronRight />
      </Box>
    </StyledHouseholdChecklistButton>
  );
};

export default HouseholdChecklistButton;
