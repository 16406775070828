import { FC } from 'react';
import { BsBuilding, BsHouseFill, BsPersonFill } from 'react-icons/bs';
import { Box, styled, themeGet } from '@qwealth/qcore';
import { complianceContact, complianceHousehold } from 'data/models/Compliance';

interface ReviewItemContainerProps {
  isOpen: boolean;
}

const ReviewItemContainer = styled(Box)<ReviewItemContainerProps>`
  width: 100%;
  height: fit-content;
  padding: ${themeGet('space.default')};
  border-radius: ${themeGet('radii.large')};
  display: flex;
  flex-direction: column;
  background-color: ${props =>
    props.isOpen ? themeGet('colors.gray.3') : themeGet('colors.gray.4')};

  &:hover {
    background-color: ${props =>
      props.isOpen ? themeGet('colors.gray.2') : themeGet('colors.gray.3')(props)};
  }
`;

interface ReviewItemProps {
  id: number;
  isOpen: boolean;
  type: 'household' | 'corp/trust' | 'client';
  household?: complianceHousehold;
  contact?: complianceContact;
  onClick: (id: number, household: complianceHousehold | undefined) => void;
}

const ReviewItem: FC<ReviewItemProps> = ({
  isOpen,
  id,
  type,
  household,
  contact,
  onClick,
}): JSX.Element => {
  return (
    <ReviewItemContainer isOpen={isOpen} onClick={() => onClick(id, household)}>
      <Box display="flex" flexDirection="row">
        <Box
          backgroundColor="blueTransparent"
          display="flex"
          width="fit-content"
          padding="default"
          borderRadius="large"
        >
          {type === 'household' && <BsHouseFill fill="#3772B8" size={28} />}
          {type === 'corp/trust' && <BsBuilding fill="#3772B8" size={28} />}
          {type === 'client' && <BsPersonFill fill="#3772B8" size={28} />}
        </Box>

        <Box marginLeft="large" display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            gap="large"
            fontSize="sectionTitle"
            fontWeight="bold"
            alignItems="center"
          >
            {household && household.name}
            {contact && contact.firstName + ' ' + contact.lastName}
          </Box>
          <Box display="flex" flexDirection="row" gap="default">
            {type === 'household' && (
              <Box display="flex" flexDirection="row" gap="default" alignItems="center">
                {/*
                // Will remove if not keeping risk level
                <Box fontWeight="bold">Risk Level:</Box>
                <Box>Household</Box> */}
              </Box>
            )}
            <Box fontWeight="bold">Status:</Box>
          </Box>
        </Box>
      </Box>
    </ReviewItemContainer>
  );
};

export default ReviewItem;
