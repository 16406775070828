// import { householdChecklist } from 'components/Compliance/ChecklistDatav2';
import {
  complianceContact,
  complianceHousehold,
  complianceQuestion,
  contactChecklist,
} from 'data/models/Compliance';
import { RootState } from 'data/store';
import { values } from 'ramda';
import { createSelector } from 'reselect';

export const selectComplianceHouseholds = (state: RootState): complianceHousehold[] => {
  return state.compliance.households;
};

export const selectComplianceHousehold = (state: RootState): complianceHousehold | undefined => {
  return state.compliance.selectedHousehold;
};

export const selectComplianceContact = (state: RootState): complianceContact | undefined => {
  return state.compliance.selectedContact;
};

export const selectComplianceCheckslists = (state: RootState): contactChecklist[] => {
  const checklists = state.compliance.checklists;
  if (checklists) {
    return checklists;
  }
  return [];
};

export const selectComplianceSelectedCheckslist = (state: RootState): complianceQuestion[] => {
  const checklist = state.compliance.selectedChecklist;
  if (checklist) {
    return checklist;
  }
  return [];
};

export const selectHouseholds = createSelector(
  selectComplianceHouseholds,
  (households: complianceHousehold[]) => households,
);

export const selectComplianceSelectedHousehold = createSelector(
  selectComplianceHousehold,
  (household: complianceHousehold | undefined) => {
    household;
  },
);

export const selectComplianceSelectedContact = createSelector(
  selectComplianceContact,
  (contact: complianceContact | undefined) => {
    contact;
  },
);

export const selectComplianceCheckslistsForHousehold = createSelector(
  selectComplianceCheckslists,
  (checklists: contactChecklist[] | undefined) => {
    return checklists;
  },
);

export const selectComplianceSelectedChecklist = createSelector(
  selectComplianceSelectedCheckslist,
  (checklist: complianceQuestion[] | undefined) => {
    return checklist;
  },
);
