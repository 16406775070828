import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { Box } from '@qwealth/qcore';

import {
  fetchComplianceHouseholds,
  getComplianceChecklistsForHousehold,
  setSelectedComplianceContact,
  setSelectedComplianceHousehold,
} from 'data/actions/compliance';
import {
  complianceContact,
  complianceHousehold,
  complianceTasks,
  shortCode,
} from 'data/models/Compliance';
import {
  selectComplianceCheckslists,
  selectComplianceContact,
  selectComplianceHousehold,
  selectHouseholds,
} from 'data/selectors/compliance';
import { useAppSelector } from 'data/store';
import { loadSingleContact } from 'data/actions/contacts';
import ReviewsCard from './Reviews/ReviewsCard';
import HouseholdCard from './Reviews/HouseholdCard';
import ComplianceChecklistCard from './Checklist/ComplianceChecklistCard';
import ComplianceHeader from './Header/ComplianceHeader';
import ComplianceTasksCard from './Tasks/ComplianceTasksCard';

const selectDocumentShortCodes = [
  'welcome_letter',
  'referral_disclosure',
  'all_ima_agreement_sigs',
  'all_ima_fee_sigs',
  'partner_office',
  'household_set_up',
  'rep_code_match',
  'ips_check',
  'ips_completed',
  'ips_language',
  'investment_restriction',
  'ips_signed',
  'advising_rep_name',
  'all_fields_populated',
];

const CompliancePage: FC = (): JSX.Element => {
  const households = useAppSelector(selectHouseholds);
  const selectedHousehold = useAppSelector(selectComplianceHousehold);
  const checklists = useAppSelector(selectComplianceCheckslists);
  const [householdQID, setHouseholdQID] = useState<string | undefined>(useParams().household);
  const selectedContact = useAppSelector(selectComplianceContact);
  const [selectedContactQID, setSelectedContactQID] = useState<string | undefined>(
    useParams().client,
  );
  const [taskName, setTaskName] = useState<complianceTasks>('IMA');
  const [taskToDisplay, setTaskToDisplay] = useState<shortCode>('SelectDocument');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllTickets();
  }, []);

  useEffect(() => {
    const paramsHousehold = getComplianceHousehold();
    paramsHousehold && dispatch(setSelectedComplianceHousehold(paramsHousehold));

    if (selectedContactQID && paramsHousehold) {
      const paramsContact = getComplianceContact(paramsHousehold);
      paramsContact && clickContact(paramsContact);
    } else {
      paramsHousehold && clickHousehold(paramsHousehold);
    }
  }, [households]);

  const getComplianceHousehold = (): complianceHousehold | undefined => {
    const household = households.find(household => household.QID === householdQID);
    household && dispatch(getComplianceChecklistsForHousehold(household));
    return household;
  };

  const getComplianceContact = (household: complianceHousehold | undefined) => {
    return household?.members.find(member => member.QID === selectedContactQID);
  };

  const clickHousehold = async (household: complianceHousehold | undefined) => {
    if (household) {
      await dispatch(setSelectedComplianceHousehold(household));
      await dispatch(getComplianceChecklistsForHousehold(household));
    }
    setSelectedContact(undefined);
    setHouseholdQID(household?.QID);
    navigate(`/compliance/${household?.QID}`);
  };

  const setSelectedContact = async (contact: complianceContact | undefined) => {
    await dispatch(setSelectedComplianceContact(contact));
  };

  const clickContact = async (contact: complianceContact | undefined) => {
    setSelectedContact(contact);

    if (contact) {
      await dispatch(loadSingleContact(contact.QID));
      await dispatch(setSelectedComplianceContact(contact));

      const selectedChecklist = checklists.find(checklist => {
        return checklist.contactQID === contact.QID;
      })?.checklist;

      changeTaskItem('SelectDocument');
    }
    contact
      ? navigate(`/compliance/${householdQID}/${contact.QID}`)
      : navigate(`/compliance/${householdQID}`);
  };

  const clickReviews = () => {
    setSelectedContact(undefined);
    navigate('/compliance');
  };

  const changeTaskItem = (changeTo: shortCode) => {
    if (changeTo === 'PDFViewer' || changeTo === 'SelectDocument' || changeTo === 'QVault') {
      setTaskToDisplay(changeTo);
    } else if (selectDocumentShortCodes.includes(changeTo)) {
      setTaskToDisplay('SelectDocument');
    } else {
      setTaskToDisplay('All Contact Info');
    }
  };

  const fetchAllTickets = async () => {
    await dispatch(fetchComplianceHouseholds());
    setIsLoading(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      <ComplianceHeader
        selectedHousehold={selectedHousehold}
        selectedClient={selectedContact?.firstName}
        clickHousehold={clickHousehold}
        clickReviews={clickReviews}
      />

      <Box display="flex" flexDirection="row" gap="large">
        {!selectedContact ? (
          <>
            <ReviewsCard setHouseholdSelected={clickHousehold} isLoading={isLoading} />
            <HouseholdCard
              household={selectedHousehold}
              selectContact={clickContact}
              isLoading={isLoading}
            />
          </>
        ) : (
          <>
            <ComplianceChecklistCard
              clickBack={() => clickContact(undefined)}
              changeTo={changeTaskItem}
            />
            <ComplianceTasksCard
              taskName={taskName}
              taskToDisplay={taskToDisplay}
              changeTo={changeTaskItem}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default CompliancePage;
