import { Dispatch } from 'redux';
import { loadHouseholdMetadata as getHouseholdMetadata } from '@qwealth/kyc';
import { createAction } from '@reduxjs/toolkit';
import { HouseholdMetadata } from '@qwealth/qdata';
import { errorHandler } from 'services/axiosService';

const PUT_HOUSEHOLD_METADATA = 'PUT_HOUSEHOLD_METADATA';
const SET_LOADING = 'SET_LOADING';

export const putHouseholdMetadata = createAction<HouseholdMetadata>(PUT_HOUSEHOLD_METADATA);
export const setLoading = createAction<boolean>(SET_LOADING);

export const loadHouseholdMetadata = (householdId: string) => (dispatch: Dispatch, getState): Promise<void> => {
  if (getState().householdMetadata.isLoading) {
    return Promise.reject();
  }
  dispatch(setLoading(true));
  dispatch(putHouseholdMetadata({ ...getState().householdMetadata, QID: householdId }));
  return getHouseholdMetadata(householdId)
    .then(householdMetadata => {
      if (householdMetadata) {
        dispatch(putHouseholdMetadata(householdMetadata));
      } else {
        throw new Error();
      }
    })
    .catch(errorHandler(dispatch, 'Failed to retrieve household. Please contact technology@qwealth.com with QID.'))
    .finally(() => dispatch(setLoading(false)));
};
