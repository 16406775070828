import axios from 'axios';
import { indexOf, sortBy } from 'lodash';
import { fetchHouseholdMembers, HouseholdMember, MEMBER_TYPES, MemberType, QContext } from '@qwealth/qdata';
import { AppThunkDispatch } from 'data/store';
import { MEMBER_ORDER } from '../refData/memberOrder';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';

export const getAvailableMemberTypes = (householdQID?: string) => async (dispatch: AppThunkDispatch): Promise<Array<MemberType>> => {
  let availableMemberTypes = MEMBER_TYPES.slice(2);
  if (householdQID) {
    const householdMemberTypes = await fetchHouseholdMembers(householdQID)
      .then(householdMemberTypes => householdMemberTypes.map(t => t.memberType as MemberType))
      .catch(QContext.getErrorHandler()(dispatch, 'Could not retrieve household members'));

    if (!householdMemberTypes.includes('Primary Client')) {
      availableMemberTypes.push('Primary Client');
    }
    if (!householdMemberTypes.includes('Secondary Client')) {
      availableMemberTypes.push('Secondary Client');
    }
    if (householdMemberTypes.includes('Non-Client Spouse')) {
      availableMemberTypes = availableMemberTypes.filter(t => t !== 'Non-Client Spouse');
    }
  }

  return sortBy(availableMemberTypes, (t) => indexOf(MEMBER_ORDER, t));
}

// TODO: deprecate this once QWAPI supports saving memberType on contact
export const saveHouseholdMember = (member: Partial<HouseholdMember>) => (dispatch: AppThunkDispatch): Promise<void> => axios
  .patch(`${REACT_APP_QWEALTH_API}/households/members/${member.id}`, { memberType: member.memberType})
  .catch(errorHandler(dispatch, 'Could not update household member type'));
