import React, { FC, ReactElement } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Form } from 'react-bootstrap';
import { Box, TextInput } from '@qwealth/qcore';
import { MutableRtqNote } from 'data/models/MutableRtqNote';
import InputForm from '../InputForm';
import { EditorWrapper } from '../EditorWrapper';

interface Props {
  isLocked: boolean;
  mutableRtqNote: MutableRtqNote;
  setMutableNote?: (state: MutableRtqNote) => void;
}

const RtqNoteField: FC<Props> = ({ isLocked, mutableRtqNote, setMutableNote }): ReactElement => {
  const { state: editorState, noteAuthor, noteDate } = mutableRtqNote;
  const error = editorState.getCurrentContent().hasText()
    ? undefined
    : 'RTQ Notes is required';
  return (
    <>
      <InputForm>
        <EditorWrapper minHeight={150} hasError={!!error}>
          <Editor
            editorState={editorState}
            handlePastedText={() => false}
            onEditorStateChange={editorState => {
              if (setMutableNote) {
                setMutableNote({ ...mutableRtqNote, state: editorState });
              }
            }}
            editorClassName="wysiwygEditor"
            readOnly={!setMutableNote || isLocked}
            toolbarHidden={!setMutableNote || isLocked}
          />
          {error ? <Box color="error">{error}</Box> : null}
        </EditorWrapper>
      </InputForm>
      <Box display="grid" gap="large" gridTemplateColumns="1fr 1fr 1fr 1fr">
        <InputForm>
          <Box textStyle="formLabel">Note Author</Box>
          <TextInput disabled value={noteAuthor} />
        </InputForm>
        <InputForm>
          <Box textStyle="formLabel">Note Date</Box>
          <TextInput disabled value={noteDate} />
        </InputForm>
        <InputForm>
          <Box textStyle="formLabel">Note Locked</Box>
          <Form.Check
            id="rtqNoteIsLocked"
            type="checkbox"
            disabled
            checked={isLocked}
          />
        </InputForm>
      </Box>
    </>
  );
};

export default RtqNoteField;
