import { FC } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Box } from '@qwealth/qcore';
import { complianceHousehold, householdChecklist } from 'data/models/Compliance';
import ComplianceHeaderButton from './ComplianceHeaderButton';

interface ComplianceHeaderProps {
  selectedHousehold: complianceHousehold | undefined;
  selectedClient: string | undefined;
  clickHousehold: (household: complianceHousehold | undefined) => void;
  clickReviews: () => void;
}

const ComplianceHeader: FC<ComplianceHeaderProps> = ({
  selectedHousehold,
  selectedClient,
  clickHousehold,
  clickReviews,
}): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" marginBottom="large" boxShadow="layout">
      <Box
        backgroundColor="primary"
        color="white"
        padding="default"
        fontSize="appTitle"
        fontWeight="bold"
        borderRadius="default"
      >
        Compliance
      </Box>
      <Box
        backgroundColor="white"
        padding="default"
        color="gray.1"
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="small"
      >
        <ComplianceHeaderButton
          name={'Reviews'}
          icon={'Folder'}
          isCurrentSection={!(selectedHousehold || selectedClient)}
          onClick={() => clickReviews()}
        />

        {selectedHousehold && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <BsChevronRight />
            <ComplianceHeaderButton
              name={selectedHousehold.name}
              icon={'House'}
              isCurrentSection={!selectedClient}
              onClick={() => clickHousehold(undefined)}
            />
          </Box>
        )}
        {selectedClient && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <BsChevronRight />
            <ComplianceHeaderButton
              name={selectedClient}
              icon={'Person'}
              isCurrentSection={true}
              onClick={() => {}}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ComplianceHeader;
