import { createAction, Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  complianceContact,
  complianceHousehold,
  complianceQuestion,
  contactChecklist,
  householdChecklist,
} from 'data/models/Compliance';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';

export const UPDATE_COMPLIANCE_CLIENT = 'UPDATE_COMPLIANCE_CLIENT';
export const GET_COMPLIANCE_HOUSEHOLDS = 'GET_COMPLIANCE_HOUSEHOLDS';
export const GET_COMPLIANCE_CONTACT_CHECKLIST = 'GET_COMPLIANCE_CONTACT_CHECKLIST';
export const SET_COMPLIANCE_SELECTED_HOUSEHOLD = 'SET_SELECTED_COMPLIANCE_HOUSEHOLD';
export const SET_COMPLIANCE_SELECTED_CONTACT = 'SET_SELECTED_COMPLIANCE_CONTACT';
export const SET_COMPLIANCE_CONTACT_CHECKLISTS = 'SET_COMPLIANCE_CONTACT_CHECKLISTS';
export const SET_COMPLIANCE_SELECTED_CHECKLIST = 'SET_COMPLIANCE_SELECTED_CHECKLIST';

export const updateComplianceClient = createAction<householdChecklist>(UPDATE_COMPLIANCE_CLIENT);
export const getComplianceHouseholds =
  createAction<complianceHousehold[]>(GET_COMPLIANCE_HOUSEHOLDS);

export const setComplianceSelectedHousehold = createAction<complianceHousehold>(
  SET_COMPLIANCE_SELECTED_HOUSEHOLD,
);

export const setComplianceSelectedContact = createAction<complianceContact | undefined>(
  SET_COMPLIANCE_SELECTED_CONTACT,
);

export const setComplianceContactChecklists = createAction<contactChecklist[]>(
  SET_COMPLIANCE_CONTACT_CHECKLISTS,
);

export const setComplianceSelectedChecklist = createAction<complianceQuestion[]>(
  SET_COMPLIANCE_SELECTED_CHECKLIST,
);

export const fetchComplianceHouseholds = () => {
  return async (dispatch: Dispatch): Promise<complianceHousehold[]> => {
    try {
      const response = await axios.get(`${REACT_APP_QWEALTH_API}/complianceChecklist/households`);
      const complianceHouseholds: complianceHousehold[] = response.data.data;
      dispatch(getComplianceHouseholds(complianceHouseholds));
      return complianceHouseholds;
    } catch (e) {
      await errorHandler(dispatch, 'API Error: Failed Fetching compliance households')(e);
      return [];
    }
  };
};

export const getComplianceChecklistForContact = async (
  contactQID: string,
): Promise<complianceQuestion[]> => {
  try {
    const response = await axios.get(`${REACT_APP_QWEALTH_API}/complianceChecklist/${contactQID}`);
    return response.data.data as complianceQuestion[];
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getContactChecklistIsComplete = (checklist: complianceQuestion[]) => {
  const hasIncompleteSection = checklist.some(section => section.children_status === 'no');
  return hasIncompleteSection ? 'no' : 'yes';
};

export const getComplianceChecklistsForHousehold = (household: complianceHousehold) => {
  return async (dispatch: Dispatch) => {
    const checklists: contactChecklist[] = [];

    for (const member of household.members) {
      try {
        const checklist = await getComplianceChecklistForContact(member.QID);
        checklists.push({
          contactQID: member.QID,
          contactName: member.firstName + ' ' + member.lastName,
          checklist: checklist,
          checklistStatus: getContactChecklistIsComplete(checklist),
        });
      } catch (e) {
        console.error(e);
      }
    }

    try {
      dispatch(setComplianceContactChecklists(checklists));
    } catch (e) {
      console.error(e);
    }
    return checklists;
  };
};

export const setSelectedComplianceHousehold = (household: complianceHousehold) => {
  return async (dispatch: Dispatch): Promise<complianceHousehold | undefined> => {
    try {
      dispatch(setComplianceSelectedHousehold(household));
    } catch (e) {
      await errorHandler(dispatch, 'Error: Failed setting compliance households')(e);
      return undefined;
    }
  };
};

export const setSelectedComplianceContact = (contact: complianceContact | undefined) => {
  return async (dispatch: Dispatch): Promise<complianceHousehold | undefined> => {
    try {
      dispatch(setComplianceSelectedContact(contact));
    } catch (e) {
      await errorHandler(dispatch, 'Error: Failed setting compliance contact')(e);
      return undefined;
    }
  };
};
