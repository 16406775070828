import { FC } from 'react';
import { Box, styled, themeGet } from '@qwealth/qcore';
import { BsCheck, BsX } from 'react-icons/bs';

interface StyledComplianceCheckboxProps {
  myColor: string;
}

const StyledComplianceCheckbox = styled(Box)<StyledComplianceCheckboxProps>`
  width: 25px;
  height: 25px;
  background-color: ${props => props.myColor};
  border-radius: ${themeGet('radii.default')};
  border-color: ${themeGet('colors.dard')};
  border: 2px;
  align-items: center;
  justify-content: center;
`;

interface CompletionCheckboxProps {
  isCompliant: boolean | undefined;
}

const CompletionCheckbox: FC<CompletionCheckboxProps> = ({ isCompliant }): JSX.Element => {
  const getColor = () => {
    if (isCompliant) {
      return themeGet('colors.green');
    } else if (isCompliant === undefined) {
      return themeGet('colors.white');
    } else {
      return themeGet('colors.red');
    }
  };

  return (
    <StyledComplianceCheckbox myColor={getColor()}>
      {isCompliant === true && <BsCheck size={24} style={{ strokeWidth: 1, color: 'white' }} />}
      {isCompliant === false && <BsX size={24} style={{ strokeWidth: 1.5, color: 'white' }} />}
    </StyledComplianceCheckbox>
  );
};

export default CompletionCheckbox;
