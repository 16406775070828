import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { REACT_APP_QWEALTH_API } from '@qwealth/qdata';
import { Box, Button } from '@qwealth/qcore';

import {
  selectComplianceCheckslists,
  selectComplianceContact,
  selectComplianceSelectedChecklist,
} from 'data/selectors/compliance';
import { useAppSelector } from 'data/store';
import { complianceQuestion, shortCode } from 'data/models/Compliance';
import { fetchComplianceHouseholds, setComplianceSelectedChecklist } from 'data/actions/compliance';
import ChecklistSection from './ChecklistSection';

interface ChecklistProps {
  changeTo: (taskToDisplay: shortCode) => void;
  clickBack: () => void;
}

const Checklist: FC<ChecklistProps> = ({ changeTo, clickBack }): JSX.Element => {
  const checklists = useAppSelector(selectComplianceCheckslists);
  const selectedChecklist = useAppSelector(selectComplianceSelectedChecklist);
  const selectedContact = useAppSelector(selectComplianceContact);
  const [complianceSubmition, setComplianceSubmition] =
    useState<Record<string, string | null | undefined>>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (checklists && selectedContact?.QID) {
      const selectedContactChecklist = checklists.find(list => {
        return list.contactQID === selectedContact.QID;
      })?.checklist;
      selectedContactChecklist &&
        dispatch(setComplianceSelectedChecklist(selectedContactChecklist as complianceQuestion[]));
    }
  }, [checklists]);

  useEffect(() => {
    setComplianceSubmition(getIdToAnswerMap(selectedChecklist as complianceQuestion[]));
  }, [selectedChecklist]);

  const getIdToAnswerMap = (checklist: complianceQuestion[]) => {
    const result: Record<string, string | null | undefined> = {};
    if (!checklist) {
      return {};
    }
    for (const item of checklist) {
      if (!item.children || item.children.length === 0) {
        result[item.id.toString()] = 'yes';
      } else {
        // If the node has children, recursively collect values from them
        const childValues = getIdToAnswerMap(item.children);
        Object.assign(result, childValues); // Merge all the values into a single map
      }
    }

    return result;
  };

  const updateQuestionAnswer = (questionId: string, option: string) => {
    const updatedSubmition = complianceSubmition ? complianceSubmition : {};
    updatedSubmition[questionId] = option;
    setComplianceSubmition(updatedSubmition);
  };

  const clickSubmit = async () => {
    const payload = { values: complianceSubmition, notes: {} };
    try {
      const response = await axios.post(
        `${REACT_APP_QWEALTH_API}/complianceChecklist/${selectedContact?.QID}`,
        payload,
      );
    } catch (error) {
      console.error('Error posting checklist review:', error);
    }
    await dispatch(fetchComplianceHouseholds());
    clickBack();
  };

  return (
    <Box display="flex" flexDirection="column" padding="large" gap="large" maxHeight="100vh">
      {selectedChecklist &&
        selectedChecklist.map(section => {
          return (
            <ChecklistSection
              key={'ChecklistSection' + section.text}
              section={section}
              changeTo={changeTo}
              updateQuestionAnswer={updateQuestionAnswer}
            />
          );
        })}

      <Button onClick={() => clickSubmit()} minHeight="50px" fontSize="large">
        Submit Review
      </Button>
      <Box minHeight="500px" />
    </Box>
  );
};

export default Checklist;
