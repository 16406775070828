import React, { FC, ReactElement, useRef, useEffect, useState } from 'react';

import { Box } from '@qwealth/qcore';
import { getFile, S3Item } from '@qwealth/qvault';

const CanvasComponent: React.FC<{ canvas: HTMLCanvasElement }> = ({ canvas }) => {
  const canvasRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.appendChild(canvas);
    }
  }, [canvas]);

  return <div ref={canvasRef} />;
};

interface PDFViewProps {
  defaultPdf: S3Item | undefined;
}

const PDFViewer: FC<PDFViewProps> = ({ defaultPdf }): ReactElement => {
  const [pdfPages, setPdfPages] = useState<(HTMLCanvasElement | undefined)[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [isRendering, setIsRendering] = useState<boolean>(false);

  const getPDF = async () => {
    if (defaultPdf) {
      const myPDFBlob = await getFile(defaultPdf.key as string);
      const pdfBlob = myPDFBlob?.slice(0, myPDFBlob?.size, 'application/pdf');
      const myUrl = await URL.createObjectURL(pdfBlob as Blob);
      setPdfUrl(myUrl);
    }
  };

  useEffect(() => {
    getPDF();
  }, []);

  return (
    <Box minWidth="100%" minHeight="100%">
      <Box minWidth="100%" height="100%" maxHeight="1100px" overflowY="scroll">
        <iframe src={pdfUrl} width="100%" height="750px" style={{ border: 'none' }} />
      </Box>
    </Box>
  );
};

export default PDFViewer;
